.card{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color:#95dada ;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
    transform: translateZ(0);
    transition: transform 0.25s ease-in-out;
}
.card:hover{
    transform: scale(1.05);
}